import React from 'react';
import '../css/refreshpopup.css';

interface WorkOutputPopup {
  data: any;
  onMessage: () => void;
  onClose: () => void;
}

const WorkOutputPopup: React.FC<WorkOutputPopup> = ({ data, onMessage, onClose }) => {
//   console.log(data)
    return (
    <div className="overlay">
      <div className="popup">
   <div>
   <h1>THIS IS A WORK IN PROGRESS</h1>
   {data.map((task: any) => (
        <div key={task}>
          {task}  
        </div>
      ))}
   </div>
<div style={{marginTop: '10%'}}>
        <button onClick={onMessage}>Message</button>
        <button style={{float: 'right', marginRight: '1%'}} onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};



export default WorkOutputPopup;