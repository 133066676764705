import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ErrorPopupProps from '../componets/refresh_popup';
import RefreshFuntion from '../componets/refresh';
// import BooleanSlider from '../componets/Slider';
import '../css/pieChart.css'
// import NestedTable from '../componets/nestedTable';
import ProgressBar from '../componets/progressBar';
import GetRowStyle from '../componets/projectsPageRowStyle';
import classNames from 'classnames';
import { useSpinner } from './spinnerContext';
import WorkOutputPopup from './workOutputPopup';


const StoryPreview = (selectedItem: any) => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [openPopUpToken, setopenPopUpToken] = useState<object | null>(null);
    const  userStory  = selectedItem['selected']
    const [tokenInvalid, settokenInvalid] = useState<string | null>(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('taiga-token');
    const isManager = localStorage.getItem('isManager');
    let tempallcurrentTasks: any[] = []
    const _ = require("lodash");
    const customOrder = ['To Do', 'Concept Art', 'Color Art', 'Review Art', 'Sculpting', 'Review Sculpting', 'Cutting', 'Supporting', 'Approved Sculpting', 'Done', 'Writing', 'Writing Review', 'Editing', 'Approved Writing'];
    const [allcurrentTasks, setallcurrentTasks] = useState<any[]>([]);
    const [allusers, setallusers] = useState<any[]>([]);
    const [alldetailinfo, setalldetailinfo] = useState<any[]>([]);
    const [infoToShow, setinfoToShow] = useState<any[]>([]);
    const [pieChartData4Closed, setPieChartData4Closed] = useState<{ name: string; value: number; progress: number; }[]>([]);
useEffect(() => {  
    // console.log(userStory)
        const fetchData = async () => {
            // console.log(isManager)
            if (isManager === 'Manager') {
              console.log('logged in')
            }
            else{
              console.log('not logged in')
                navigate('/');
            }
              try {
                const response = await axios.get('https://api.taiga.io/api/v1/tasks?project=1575333', {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "x-disable-pagination": 'True'
                  },
                });
                response.data.forEach(function(task: any) {
                 if(userStory.subject === task?.user_story_extra_info?.subject){
                  task.username = task?.assigned_to_extra_info?.username
                  task.full_name = task?.assigned_to_extra_info?.full_name_display
                  task.status = task?.status_extra_info?.name
                  task.url = 'https://tree.taiga.io/project/sordane-publishing/task/'+ task.ref
                  task.mainTag = task?.tags?.[0]?.[0]
                  tempallcurrentTasks.push(task)
                 }
                });
                const sortedItems = [...tempallcurrentTasks].sort((a, b) => {
                      return customOrder.indexOf(a.status) - customOrder.indexOf(b.status);
                    });
                    console.log(sortedItems)
                setallcurrentTasks(sortedItems);
                setPieChartData4Closed(await flattenData4Closed(sortedItems))
                // console.log(pieChartData)
              } catch (err: any) {
                console.log(err)
                // setError('Failed to fetch data');
                if(err?.response?.data?.detail === "Given token not valid for any token type"){
                  settokenInvalid(err.message);
                  // RefreshButton();
                }
              }
              try {
                const response = await axios.get('https://api.taiga.io/api/v1/users?project=1575333', {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "x-disable-pagination": 'True'
                  },
                });
                setallusers(response.data)
              } catch (err: any) {
                  console.log(err)
                if(err?.response?.data?.detail === "Given token not valid for any token type"){
                  settokenInvalid(err.message);
                  // RefreshButton();
                }
              }

    };

    fetchData()
}, []);
async function loadExtraInfo() {
  showSpinner();
  setalldetailinfo([]);
  await createExtraInfo()
  let response = await massageData()
  await loadUsersAndReplace(response)
  hideSpinner();
}
async function loadUsersAndReplace(info: any[]) {
    // console.log(info)
    // console.log(allusers)
  for (const user of allusers) {
    // console.log(JSON.stringify(user.id) + ' is ' + user.full_name)

    for (let i = 0; i < info.length; i++) {
      let task = info[i];
      // let updatedTask = task.replace(JSON.stringify(user.id), user.full_name);
      if(task){
      let updatedTask = task.replace(new RegExp(JSON.stringify(user.id), 'g'), user.full_name);
      let regex = new RegExp(`\\b${user.full_name}\\b`, 'g');
      updatedTask = updatedTask.replace(regex, (match: any, offset: any, string: string | any[]) => {
        return string.indexOf(match) === offset ? match : '';
      });
      info[i] = updatedTask;
      // console.log(`Replaced in task ${i}:`, updatedTask);
      }
    }
    }
    // console.log(info)
  setinfoToShow(info)
  openPopUp(info)
}
async function massageData() {
  let tempArray = [...infoToShow]
  for (const taskToCheckEdit of alldetailinfo) {
    // console.log(taskToCheckEdit.user0)
    // console.log(taskToCheckEdit.user1)
    let user0 = JSON.stringify(taskToCheckEdit.user0)
    let user1 = JSON.stringify(taskToCheckEdit.user1)
    let keyId = taskToCheckEdit.key.replace("tasks.task:", "");
    let index = allcurrentTasks.findIndex(task => JSON.stringify(task.id) === keyId);
    // console.log(index)
    if(tempArray[index]){
      tempArray[index] = tempArray[index] + user0 + ', ' + user1 + ', '
    }
    else{
      tempArray[index] = allcurrentTasks[index].subject + ' By: ' + user0 + ', ' + user1 + ', '
    }
    tempArray[index] = tempArray[index].replace("null,", "");
  }
  // console.log(tempArray)
  setinfoToShow(tempArray)
  return tempArray
}
  async function createExtraInfo() {
  // console.log(allcurrentTasks)
  for (const task of allcurrentTasks) {
   let string = 'https://api.taiga.io/api/v1/history/task/' + task.id;
   try {
     const response = await axios.get(string, {
       headers: {
         Authorization: `Bearer ${token}`,
         "x-disable-pagination": 'True'
       },
     });
     for (const datatoUse of response.data) {
    //  console.log(datatoUse);
       let useArray = alldetailinfo;
       if(datatoUse?.diff?.assigned_to){
        let creationItem = {
          key: datatoUse.key,
          user0: datatoUse?.diff?.assigned_to[0],
          user1: datatoUse?.diff?.assigned_to[1]
        };
        useArray.push(creationItem);
        setalldetailinfo(useArray);
       }
     }
    //  console.log(alldetailinfo)
   } catch (err: any) {
     console.log(err);
     // setError('Failed to fetch data');
     if (err?.response?.data?.detail === "Given token not valid for any token type") {
       settokenInvalid(err.message);
       // RefreshButton();
     }
   }
 }
}
const openPopUp = (info: any) => {
  setopenPopUpToken(info);
  
};
const handleMessage = async () => {

  setopenPopUpToken(null);

};

const handleClose = () => {
  setinfoToShow([])
  setopenPopUpToken(null);
};
const handleYes = async () => {
    // console.log('User clicked Yes');
    await RefreshFuntion();
    settokenInvalid(null);
    navigate('/');
    window.location.reload();
  
  };
  
  const handleNo = () => {
    navigate('/logout');
  };
//   console.log(pieChartData4Column)
  return (
    <div style={{height: '100%'}}>
              {tokenInvalid && (
        <ErrorPopupProps message={tokenInvalid}  onYes={handleYes}
        onNo={handleNo}/> 
      )}      
      {openPopUpToken && (
                  <WorkOutputPopup data={infoToShow}  onMessage={handleMessage}
                  onClose={handleClose}/>
      )}
        <div  style={{width: '100%', float:'left'}}>
        <ProgressBar progress={pieChartData4Closed[1]} />
<div style={{borderTop: '1px black solid'}}>
    <div style={{textAlign: 'center', fontWeight: 'bold', height: '45px'}}>
    {userStory.subject} < div style={{cursor:'pointer'}} onClick={() => loadExtraInfo()}>(WIP) Workload Output</div>
    </div>
    <div >
            <div className='showItems'>
            <div className='storyName'>
             Task
            </div> 
            <div className='status'>
             Person
            </div>
            <div className='status'>
             Tag
            </div>  
            <div className='status'>
             Status
            </div>
            </div>
        </div>
{allcurrentTasks.map((task) => (
        <div  key={task.id}>
            <div className={classNames(GetRowStyle(task.due_date, task.is_closed), 'showItems')}>
            <div className='storyName'>
             {task.subject}
            </div> 
            <div className='status'>
             {task.username}
            </div> 
            <div className='status'>
             {task.mainTag}
            </div> 
            <div className='status'>
             {task.status}
            </div>
            </div>
        </div>
      ))}
</div>
        
        </div>
        
</div>
  );
};

export default StoryPreview;

function flattenData4Closed(allcurrentTasks: any[]) {
// console.log(allcurrentTasks)

  // Transform data to count occurrences
const countData = allcurrentTasks.reduce((acc, item) => {
  acc[item.is_closed] = (acc[item.is_closed] || 0) + 1;
  return acc;
}, {});

// Convert to array format for pie chart
const pieChartData = Object.keys(countData).map(key => ({
  name: key,
  value: countData[key],
  progress: Math.round(((allcurrentTasks.length-countData[key])/allcurrentTasks.length)*100)
}));
// console.log(pieChartData)
return pieChartData
}

